import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Chip, CircularProgress, Icon, Tab, Tabs } from '@mui/material';

import Loading from '@/components/utility/Loading';
import SpotifySearchArtist from '@/components/utility/microcomponents/SpotifySearchArtist';
import AddGenreModal from '@/components/utility/modals/AddGenreModal';
import UserHeader from '@/components/utility/navigation/UserHeader';
import useArtist from '@/hooks/artist/useArtist';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import { SpotifyArtistSearchModel } from '@/models/Spotify';
import AccountAPI from '@/network/AccountAPI';
import ArtistAPI from '@/network/ArtistAPI';
import { handleApiError } from '@/utility/api';

const ProfilePage = () => {
  const { t } = useTranslation();

  const { accountId, refetchAccount } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const { artist, refetchArtist } = useArtist();

  const [isGenresModalOpen, setIsGenresModalOpen] = useState<boolean>(false);
  const [newArtistLoading, setNewArtistLoading] = useState<boolean>(false);
  const [isLoadingGenres, setIsLoadingGenres] = useState<boolean>(false);

  const [value, setValue] = useState<number>(0);

  const updateArtistGenres = useCallback(
    async (genres: string[]) => {
      setIsLoadingGenres(true);
      try {
        if (!artist) return;

        await ArtistAPI.updateArtist({ artistId: artist.id, artistDetails: { ...artist.details, genres } });
        await refetchArtist();
      } catch (error: unknown) {
        handleApiError({
          error,
          dispatchSnackbar,
          customMessage: 'Error updating genres, you must have at least one genre selected',
        });
      } finally {
        setIsLoadingGenres(false);
      }
    },
    [artist, dispatchSnackbar, refetchArtist]
  );

  const handleAddGenre = useCallback(
    (genre: string) => {
      if (artist?.details?.genres.includes(genre)) return;
      updateArtistGenres([...(artist?.details?.genres ?? []), genre]);
    },
    [artist, updateArtistGenres]
  );

  const handleRemoveGenre = useCallback(
    (genre: string) => {
      updateArtistGenres((artist?.details.genres ?? []).filter((value) => value !== genre));
    },
    [artist, updateArtistGenres]
  );

  const updateAccountSpotifyId = useCallback(
    async (newArtist: SpotifyArtistSearchModel) => {
      setNewArtistLoading(true);
      try {
        if (!accountId) return;

        await AccountAPI.updateAccount({ accountId, data: { spotifyArtistId: newArtist.id } });
        await refetchAccount();
        await refetchArtist();
      } catch (error: unknown) {
        handleApiError({
          error,
          dispatchSnackbar,
          customMessage: 'Error updating artist',
        });
      } finally {
        setNewArtistLoading(false);
      }
    },
    [accountId, dispatchSnackbar, refetchAccount, refetchArtist]
  );

  return (
    <div data-testid="profile-page" className="page-content">
      <AddGenreModal
        title={'COMMON.ADD-GENRES'}
        isOpen={isGenresModalOpen}
        onClose={() => setIsGenresModalOpen(false)}
        outputGenre={(genre: string) => {
          handleAddGenre(genre);
          setIsGenresModalOpen(false);
        }}
      />
      <UserHeader title="PAGE-TITLES.PROFILE" isProfile={true} />
      {newArtistLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {!newArtistLoading && (
        <>
          <div className="hide-mll-up">
            <Box
              sx={{
                width: '100%',
                marginTop: '20px',
              }}
            >
              <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} aria-label="basic tabs example">
                <Tab label={t('NAVIGATION.PROFILE')} data-testid="to-do-open" />
                <Tab label={t('COMMON.GENRES')} data-testid="to-do-done" />
              </Tabs>
            </Box>
          </div>
          <div className="hide-mll-up">
            {value === 0 && (
              <div className="profile-image-and-bio">
                <img
                  src={
                    artist?.details?.images && artist?.details?.images.length > 0
                      ? artist.details?.images[0].url
                      : '/images/profile-placeholder.svg'
                  }
                  alt=""
                ></img>
                <div className="p20 content">
                  <h3 className="mb8">{artist?.details?.name}</h3>
                  <div className="card">
                    <div className="d-flex jc-start mb8">
                      <img src={`/images/logos/spotify-logo.svg`} alt={`spotify`} className="activity-logo" />
                      <p className={`small text-brand spotify capitalize pl8 mt-2`}>{t('BRAND.SPOTIFY')}</p>
                    </div>

                    <p className="small text-faded">{artist?.details?.description}</p>
                  </div>
                </div>
              </div>
            )}
            {value === 1 && (
              <>
                <div className="card mb20">
                  <SpotifySearchArtist
                    spotifyArtist={(newArtist: SpotifyArtistSearchModel) => updateAccountSpotifyId(newArtist)}
                    isChangeArtist={true}
                  />
                </div>
                <div className="card">
                  <div className="d-flex jc-start gap8 mt8 flex-wrap">
                    {artist?.details?.genres?.map((item) => (
                      <Chip
                        key={item}
                        label={<p className="capitalize">{item}</p>}
                        className="text-left"
                        data-testid={`social-chip-${item}`}
                        deleteIcon={<Icon data-testid={`social-chip-${item}-delete`}>cancel</Icon>}
                        onDelete={() => handleRemoveGenre(item)}
                      />
                    ))}
                  </div>
                  <div className="d-flex">
                    <Button
                      className="btn-white ml-auto mb0"
                      onClick={() => {
                        setIsGenresModalOpen(true);
                      }}
                    >
                      {t('COMMON.ADD-GENRE')}
                    </Button>
                  </div>
                </div>
                <div className="text-center p48">
                  <h4>{t('COMMON.WHY-ADD-GENRES')}</h4>
                  <p className="text-faded">{t('COMMON.WHY-ADD-GENRES-DESCRIPTION')}</p>
                </div>
              </>
            )}
          </div>
          <div className="hide-mll-down">
            <div className="d-flex flex-wrap gap20 mt20">
              <div className="profile-image-and-bio flex-w50p">
                <img
                  src={
                    artist?.details?.images && artist?.details?.images.length > 0
                      ? artist?.details?.images[0].url
                      : '/images/profile-placeholder.svg'
                  }
                  alt=""
                ></img>
                <div className="p20 content">
                  <h3 className="mb8">{artist?.details?.name}</h3>
                  <div className="card">
                    <div className="d-flex jc-start mb8">
                      <img src={`/images/logos/spotify-logo.svg`} alt={`spotify`} className="activity-logo" />
                      <p className={`small text-brand spotify capitalize pl8 mt-2`}>{t('BRAND.SPOTIFY')}</p>
                    </div>

                    <p className="small text-faded">{artist?.details?.description}</p>
                  </div>
                </div>
              </div>
              <div className="flex-w50p flex-grow">
                <div className="card mb20">
                  <SpotifySearchArtist
                    spotifyArtist={(newArtist: SpotifyArtistSearchModel) => updateAccountSpotifyId(newArtist)}
                    isChangeArtist={true}
                  />
                </div>
                <div className="card">
                  <div className="d-flex jc-start gap8 mt8 flex-wrap">
                    {artist?.details?.genres?.map((item) => (
                      <Chip
                        key={item}
                        label={<p className="capitalize">{item}</p>}
                        className="text-left"
                        data-testid={`social-chip-${item}`}
                        deleteIcon={<Icon data-testid={`social-chip-${item}-delete`}>cancel</Icon>}
                        onDelete={() => handleRemoveGenre(item)}
                      />
                    ))}
                  </div>
                  <div className="d-flex">
                    <Button
                      className="btn-white ml-auto mb0"
                      disabled={isLoadingGenres}
                      onClick={() => {
                        setIsGenresModalOpen(true);
                      }}
                    >
                      {isLoadingGenres ? <CircularProgress size={16} /> : t('COMMON.ADD-GENRE')}
                    </Button>
                  </div>
                </div>
                <div className="text-center p48">
                  <h4>{t('COMMON.WHY-ADD-GENRES')}</h4>
                  <p className="text-faded">{t('COMMON.WHY-ADD-GENRES-DESCRIPTION')}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
